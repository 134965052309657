import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOut, containerVariants, child } from "utils/animation.js";

import Preloader from "components/Preloader.js";
import Progressbar from "components/Progressbar.js";

import imgIconDummy from "assets/images/icon-dummy.svg";
import imgNote from "assets/images/icon-note.svg";

const Questions = ({
  showPreloader,
  currentQuestion,
  setCurrentQuestion,
  filterCurrentQuestion,
  handleAnswerOptionClick,
  categoryClass,
}) => {
  return (
    <>
      {showPreloader && <Preloader />}

      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`wrapper quiz-questions h-100 w-100 p-2 d-flex justify-content-center align-items-end align-items-sm-center bg-transparent ${categoryClass}`}>
        <div className="inner-wrapper position-relative rounded-4 text-center p-3 p-sm-5 py-4 py-sm-6 ">
          {/* <Progressbar currentQuestion={currentQuestion} /> */}
          <div className="quiz-content h-100 mt-0 pb-3">
            <AnimatePresence exitBeforeEnter>
              <motion.div
                className="h-100"
                key={currentQuestion}
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit">
                <div className="h-100 d-flex align-items-end align-items-center flex-column">
                  <div>
                    <motion.p variants={child} className="lead text-white mt-4 mb-3 mt-sm-5 fst-italic">
                      {filterCurrentQuestion.questionText}
                    </motion.p>
                    <motion.h3
                      variants={child}
                      className="mb-4 py-2 py-md-4 px-2 fw-normal"
                      >
                      {filterCurrentQuestion.questionDescription}
                    </motion.h3>

                    <div
                        className={`${
                            filterCurrentQuestion.type === 'textimg'
                                ? "image-question-answer-wrapper"
                                : "text-question-answer-wrapper"
                        }`}>
                    {filterCurrentQuestion.answerOptions.map(
                      (answerOption, index) => (
                        <motion.div
                          className={`d-inline ${
                              answerOption.answerImg
                                ? "image-answer-wrapper"
                                : "text-answer-wrapper"
                            }`}
                          key={index}
                          variants={child}>
                          <button
                            className={`btn btn-question p-options mt-3 mb-1  ${
                              answerOption.answerImg
                                ? "image-answer"
                                : "text-answer"
                            }`}
                            onClick={() => {
                              handleAnswerOptionClick(
                                answerOption.path,
                                answerOption.productTags,
                                answerOption.answerText
                              );
                            }}>
                            {/* Show image if question answerImg true */}
                            {answerOption.answerImg ? (
                              <>
                                <div
                                    style={{"backgroundColor": answerOption.color, "borderColor": answerOption.color}}
                                    className=" d-flex align-items-center justify-content-center">
                                  <img src={require(`assets/images/icons/${answerOption.answerImg}`)} />
                                </div>
                                <p className="lead mt-2 mb-0">
                                  {answerOption.answerText}
                                </p>
                              </>
                            ) : (
                              <span>{answerOption.answerText}</span>
                            )}
                          </button>
                        </motion.div>
                      )
                    )}
                    </div>
                  </div>

                  {filterCurrentQuestion.questionInfo &&
                  <motion.div
                      variants={child}
                      className="note d-flex align-items-center mx-auto mt-auto mb-5 mb-sm-0">
                    <div className="image pe-3">
                      <img src={imgNote}/>
                    </div>
                    <div className="text text-start">
                      <small>
                        {filterCurrentQuestion.questionInfo}
                      </small>
                    </div>
                  </motion.div>
                  }
                </div>
                {currentQuestion > 1 && (
                  <button
                    onClick={() => setCurrentQuestion(currentQuestion - 1)}
                    className="btn btn-back text-white">
                    <i className="bi bi-arrow-left"></i>&nbsp;Nazaj
                  </button>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Questions;
