import { motion } from "framer-motion";
import { fadeInOut, containerVariants, child } from "utils/animation.js";

import imgStarSnow from "assets/images/icon_snowstar.svg";
import imgBraun from "assets/images/logo-braun.svg";

const showIntro = ({ startQuiz }) => {
  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="wrapper quiz-intro h-100 w-100 p-2 d-flex justify-content-center align-items-end align-items-sm-center bg-transparent">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="inner-wrapper position-relative rounded-4 text-center p-3 p-sm-5 pt-4 pb-5 pt-sm-6 pb-sm-6 ">
          <a href="/" rel="noopener noreferrer">
            <img className="logo" src={imgBraun} />
          </a>

          <motion.h1 variants={child} className="h1-welcome mt-4 mt-sm-0 mb-4 ">
            <span className="text-creme mb-3">
              <img className="pe-2 d-none d-sm-inline" src={imgStarSnow} />
              PAMETNI POMOČNIK
            </span>
            Odkrij svoj popoln izdelek za osebno nego
          </motion.h1>

          <motion.p variants={child} className="lead">
            Enostavno do najboljšega izdelka glede na želeni način urejanja, predel telesa in druge lastnosti, ki so pomembne zate.
          </motion.p>
          <div className="text-center usps mx-auto my-2 my-sm-4">
            <div className="row">
              <motion.div variants={child} className="col-4">
                <img className="icon" src="svg/icon-fill.svg" />
                <p className="pt-3">Reši kviz, povej svoje želje in zahteve.</p>
              </motion.div>
              <motion.div variants={child} className="col-4">
                <img className="icon" src="svg/icon-assist.svg" />
                <p className="pt-3">
                  Pametni pomočnik bo pobrskal med Braun izdelki
                </p>
              </motion.div>
              <motion.div variants={child} className="col-4">
                <img className="icon" src="svg/icon-result.svg" />
                <p className="pt-3">
                  in priporočil takšnega, ki najbolj ustreza tvojim željam.
                </p>
              </motion.div>
            </div>
          </div>
          <motion.button
            variants={child}
            className="btn btn-creme rounded-pill mt-3"
            onClick={startQuiz}>
            Reši kviz
          </motion.button>
        </motion.div>
      </motion.div>
    </>
  );
};

export default showIntro;
