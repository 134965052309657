import React, {useState, useEffect, useRef} from "react";
import "assets/styles/style.scss";
import {motion, AnimatePresence} from "framer-motion";
import quizApi from "data/apiQuestions.json";
import quizProducts from "data/apiProducts.json";

import Intro from "views/Intro";
import Questions from "views/Questions";
import Results from "views/Results";

export default function App() {
    // When true intro will be displayed
    const [showIntro, setShowIntro] = useState(true);
    // When true results will be displayed
    const [showProducts, setShowProducts] = useState(false);
    // Recommended products showPreloader
    const [showPreloader, setShowshowPreloader] = useState(false);
    // Current question ID (we use id from API instead of index)
    const [currentQuestion, setCurrentQuestion] = useState(1);
    // Array of products that are determined by user answer choice
    const [chosenProducts, setchosenProducts] = useState([]);
    // Array of chosen answers
    const [chosenAnswer, setChosenAnswer] = useState([]);
    // Get first category (moški/ženska) for further styling
    const [categoryClass, setCategoryClass] = useState("");

    const startQuiz = () => {
        setShowIntro(false);
        setShowProducts(false);
        setCurrentQuestion(1);
        setChosenAnswer([]);
        setchosenProducts([]);
        window.plausible('start')
    };

    const handleAnswerOptionClick = (path, productTags, answerText) => {
        window.scrollTo(0, 0);
        const nextQuestion = path;

        // Add product to array based on chosen answer
        if (productTags !== null) {
            setchosenProducts((current) => [...current, productTags]);
        }

        // Check whether next question exists otherwise show results
        if (nextQuestion <= quizApi.content.length) {
            setTimeout(() => {
                setCurrentQuestion(nextQuestion);

            }, 500);
        } else {
            //setShowshowPreloader(true);
            setTimeout(() => {
                setShowIntro(true);
                setShowshowPreloader(false);
                setShowProducts(true);
                setTimeout(() => {
                    setShowIntro(false);
                }, 6000 * (Math.random() + .5))
            }, 1000);
        }
        setChosenAnswer((current) => [...current, answerText]);
    };

    // Find object of current question
    const filterCurrentQuestion = quizApi.content.find(
        (obj) => obj.id === currentQuestion
    );

    // Check if we have 100% productTags match
    const filterProductsEvery = quizProducts.filter((item) => {
        let results = chosenProducts.every((tag) => {
            return item.productTags.includes(tag);
        });

        return results;
    });

    // Check if we have more than 1 productTags match

    quizProducts.map((item) => {
        item.score = 0;
        chosenProducts.forEach((n) => {
            if (item.productTags.includes(n)) {
                item.score++;
            } else {
            }
        });

        return item;
    });

    const filterProductsSome = quizProducts.filter((item) => {
        var result = chosenProducts.filter((n) => {
            return item.productTags.includes(n);
        });

        if (result.length > 1) {
            return result;
        }
    });

    // If 100% match show that otherwise fallback to at least 1 match
    const filterProducts = () => {
        if (filterProductsEvery.length > 0) {
            return filterProductsEvery;
        } else {
            return filterProductsSome;
        }
    };

    // Set class based on first answer
    useEffect(() => {
        setTimeout(() => {
            if (chosenAnswer[0] === "Moška nega") {
                setCategoryClass("moska-nega");
            }
            if (chosenAnswer[0] === "Ženska nega") {
                setCategoryClass("zenska-nega");
            }
        }, 600);
    }, [chosenAnswer]);

    return (
        <AnimatePresence>
            <div className="quiz d-flex justify-content-center">
                {
                    showProducts ? (
                        <Results
                            setShowIntro={setShowIntro}
                            showIntro={showIntro}
                            filterProducts={filterProducts}
                            categoryClass={categoryClass}
                            startQuiz={startQuiz}
                        />
                    ) : showIntro ? (
                        <Intro startQuiz={startQuiz}/>
                    ) : (
                        <Questions
                            showPreloader={showPreloader}
                            currentQuestion={currentQuestion}
                            setCurrentQuestion={setCurrentQuestion}
                            filterCurrentQuestion={filterCurrentQuestion}
                            handleAnswerOptionClick={handleAnswerOptionClick}
                            categoryClass={categoryClass}
                        />
                    )}

                {/* for dev, temp*/}
                <div className="dev-filtered-products">
                    <div>
                        {chosenProducts.map((item, index) => {
                            return <span key={index}>{item}</span>;
                        })}
                    </div>
                    <div>
                        {filterProducts().map((item, index) => {
                            return <span key={index}>{item.productName}</span>;
                        })}
                    </div>
                </div>
                <div className="footer-logo">
                    <a href="https://escapebox.si/?utm_source=kviz&utm_medium=logo&utm_id=braun-priporocilni"
                       target="_blank">
                        <img
                            className="img-fluid"
                            src="images/Escapebox-logo.png"
                            alt="Escapebox"/>
                    </a>
                </div>
            </div>
        </AnimatePresence>
    );
}
