import { motion } from "framer-motion";
import { fadeInOut, containerVariants, child } from "utils/animation.js";

import imgStarSnow from "assets/images/icon_snowstar.svg";
import imgBraun from "assets/images/logo-braun.svg";
import Preloader from "../components/Preloader";
import Calculating from "../components/Calculating";
import Intro from "./Intro";
import Questions from "./Questions";
import React from "react";

const ShowResults = ({
  setShowIntro,
 showIntro,
  filterProducts,
  categoryClass,
  startQuiz,
}) => {
  window.plausible('pageview', {u: 'https://braun-bigbang-priporocilni.escapebox.si/results'})
  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className={`wrapper results h-100 w-100 p-2 d-flex justify-content-center  align-items-sm-center bg-transparent ${categoryClass}`}>

                  <motion.div
                      variants={containerVariants}
                      initial="hidden"
                      animate="visible"
                      className="inner-wrapper position-relative rounded-4 text-center p-3 p-sm-5 py-4 py-sm-6 ">
                      {
                          showIntro ? (
                              <div className="d-flex align-items-center justify-content-center h-100">
                              <Calculating />
                              </div>
                      ) : (
<div>
                              <a href="/" rel="noopener noreferrer">
                              <img className="logo" src={imgBraun} />
                              </a>

                          {filterProducts()
                              .sort((a,b) => {
                              if(b.score - a.score === 0){
                              return .5 - Math.random() ;
                          }
                              return b.score - a.score;
                          })
                              .filter((item, index) => {
                              return index === 0
                          })
                              .map((item, index) => {
                              return (
                              <div className="row g-5 mb-5 mt-2 text-start" key={index}>
                              <div className="col-sm-4">
                              <img className="w-100" src={item.productImg} />
                              </div>

                              <div className="col-sm-8">
                              <motion.h1 variants={child} className="mt-4 mt-sm-0 mb-4 ">
                              <span className="lead text-creme mb-3">
                              Izdelek, ki najbolj ustreza tvojim željam je
                              </span>
                          {item.productName}
                              </motion.h1>
                              <motion.a
                              variants={child}
                              className="btn btn-creme rounded-pill mt-3"
                              href={`${item.productLink}&utm_source=kviz&utm_medium=escapebox&utm_campaign=priporocilni-kviz`}
                              onClick={() => {window.plausible('CTR')}}
                              target="_blank">
                              Poglej izdelek
                              </motion.a>
                              </div>
                              </div>
                              );
                          })}

                              <button
                              onClick={() => startQuiz()}
                              className="btn btn-back text-white">
                              <i className="bi bi-arrow-left"></i>&nbsp;Reši še enkrat
                              </button>
</div>
                      )}
                  </motion.div>
      </motion.div>
    </>
  );
};

export default ShowResults;
