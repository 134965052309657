export const fadeInOut = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: .35,
      ease: "easeInOut",
    },
  },
  exit: {
    opacity: 0,
  },
};

export const child = {
  hidden: { translateY: "-35px", opacity: 0 },
  visible: {
    translateY: 0,
    opacity: 1,
    transition: {duration: 1.15, type: "spring"},
  },
};
